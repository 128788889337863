import React, { useState, useEffect, useRef } from 'react';
import firebase from '../../firebaseConfige'; // Ensure the correct path to your firebase.js file
import './video.scss';
import Navbar from '../../componet/topbar/Topbar';

const VideoUp = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [userProfile, setUserProfile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userProfileRef = firebase.firestore().collection('admin-user').doc(currentUser.uid);
        const userProfileSnapshot = await userProfileRef.get();
  
        if (userProfileSnapshot.exists) {
          setUserProfile(userProfileSnapshot.data());
        } else {
          console.log('No user profile found.');
        }
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }
    if (!selectedFile.type.startsWith('video/')) {
      console.error('Invalid file type. Please select a video.');
      return;
    }
    try {
      const videoURL = URL.createObjectURL(selectedFile);
      setSelectedFile(selectedFile);
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        videoRef.current.src = videoURL;
      };
      video.src = videoURL;
    } catch (error) {
      console.error('Error creating object URL:', error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a video to upload.');
      return;
    }
    setUploadStatus('uploading');
    try {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        console.log('No user signed in.');
        return;
      }
      const storageRef = firebase.storage().ref();
      const userId = currentUser.uid;
      const fileName = selectedFile.name;
      const fileRef = storageRef.child(`videos/${userId}/${fileName}`);
      const metadata = {
        contentType: selectedFile.type,
      };
      const uploadTask = fileRef.put(selectedFile, metadata);
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          console.error('Upload error:', error);
          setUploadStatus('error');
        },
        async () => {
          setUploadStatus('success');
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          // Now, you can save the downloadURL, title, and description to your Firestore database
          saveVideoDataToFirestore(downloadURL);
        }
      );
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus('error');
    }
  };

  const saveVideoDataToFirestore = async (downloadURL) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        console.log('No user signed in.');
        return;
      }
      const userId = currentUser.uid;
      const videoData = {
        title: title,
        description: description,
        videoURL: downloadURL,
        // You can add more fields here as per your requirements
      };
      await firebase.firestore().collection('videos').doc(userId).collection('user-videos').add(videoData);
      console.log('Video data saved successfully.');
    } catch (error) {
      console.error('Error saving video data:', error);
    }
  };

  return (
    <div className="user">
      <div className="usern">
        <Navbar />
        
      </div>
      <div className="video-upload">
        <h1>Upload a Video</h1>
        <input type="file" accept="video/*" onChange={handleFileChange} />
        <input 
          type="text" 
          placeholder="Title" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
        />
        <textarea 
          placeholder="Description" 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
        />
        {selectedFile && (
          <video ref={videoRef} controls width="420" height="240">
            Your browser does not support the video tag.
          </video>
        )}
        <button onClick={handleUpload} disabled={uploadStatus === 'uploading'}>
          {uploadStatus === 'idle' && 'Upload Video'}
          {uploadStatus === 'uploading' && 'Uploading...'}
          {uploadStatus === 'success' && 'Upload Successful'}
          {uploadStatus === 'error' && 'Upload Failed'}
        </button>
      </div>
    </div>
  );
};

export default VideoUp;
