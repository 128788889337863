import React, { useState, useEffect } from 'react';
import firebase from '../../firebaseConfige'; // Corrected import
import YouTube from 'react-youtube';
import './uservideos.scss';

const UserVideos = ({ userId }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchUserVideos(userId);
  }, [userId]);

  const fetchUserVideos = async (userId) => {
    try {
      const userVideosRef = firebase.firestore().collection('admin-user').doc(userId).collection('videos');
      const snapshot = await userVideosRef.get();
      const userVideos = snapshot.docs.map(videoDoc => ({ id: videoDoc.id, ...videoDoc.data() }));
      setVideos(userVideos);
    } catch (error) {
      console.error('Error fetching user videos:', error);
    }
  };

  const extractVideoId = (videoUrl) => {
    const urlParams = new URLSearchParams(new URL(videoUrl).search);
    return urlParams.get('v');
  };

  const handleApprove = async (videoId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(userId).collection('videos').doc(videoId).update({ approved: true });
      setVideos(prevVideos => prevVideos.map(video => video.id === videoId ? { ...video, approved: true } : video));
    } catch (error) {
      console.error(`Error approving video ${videoId} in Firebase:`, error);
    }
  };

  const handleDecline = async (videoId) => {
    // Add logic to decline video if needed
    console.log(`Video with ID ${videoId} declined`);
  };

  return (
    <div className="userVideosContainer">
      <h2>Videos uploaded by this user</h2>
      {videos.map(video => (
        <div key={video.id} className="videoCard">
          <div className="videoPlayer">
            <YouTube videoId={extractVideoId(video.videoUrl)} />
          </div>
          <div className="videoDetails">
            <p>Uploader: {video.uploader}</p>
            <p>Category: {video.category}</p>
          </div>
          {!video.approved && (
            <div className="actions">
              <button className="approveButton" onClick={() => handleApprove(video.id)}>Approve</button>
              <button className="declineButton" onClick={() => handleDecline(video.id)}>Decline</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UserVideos;
