import React, { useState, useEffect } from 'react';
import './latevid.scss';
import Navbar from '../../componet/topbar/Topbar';
import firebase from '../../firebaseConfige'; // Import your Firebase configuration

const LateVid = () => {
  const [videos, setVideos] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); // State to hold current user data

  useEffect(() => {
    fetchCurrentUser();
    fetchVideosFromFirebase();
  }, []);

  const fetchCurrentUser = () => {
    // Fetch current user's data from Firebase Authentication
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  };

  const fetchVideosFromFirebase = async () => {
    try {
      const userVideosRef = firebase.firestore().collectionGroup('user-videos');
      const snapshot = await userVideosRef.get();
      const fetchedVideos = snapshot.docs.map(async (doc) => {
        const data = doc.data();
        const userId = doc.ref.parent.parent.id;
        console.log('User ID:', userId); // Log user ID for debugging

        // Fetch username from currently logged-in user
        const username = currentUser ? currentUser.displayName : 'Unknown';

        return {
          id: doc.id,
          title: data.title,
          description: data.description,
          videoURL: data.videoURL,
          username: username
        };
      });

      // Wait for all fetchedVideos promises to resolve
      const resolvedVideos = await Promise.all(fetchedVideos);
      setVideos(resolvedVideos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const renderVideo = (video) => {
    const handleApprove = async () => {
      try {
        // Perform approval logic here, such as updating the video document in Firestore
        console.log(`Video ${video.id} approved`);
      } catch (error) {
        console.error('Error approving video:', error);
      }
    };

    const handleDecline = async () => {
      try {
        // Perform decline logic here, such as marking the video as declined in Firestore
        console.log(`Video ${video.id} declined`);
      } catch (error) {
        console.error('Error declining video:', error);
      }
    };

    return (
      <div key={video.id} className="videoContainer">
        <video controls>
          <source src={video.videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="know">
          <div className="videoInfo">
            <p>Title: {video.title}</p>
            <p>Description: {video.description}</p>
            <p>Uploader: {video.username}</p>
          </div>
          <div className="actionButtons">
            <button className="approveButton" onClick={handleApprove}>Approve</button>
            <button className="declineButton" onClick={handleDecline}>Decline</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="lateVid">
      <Navbar />
      <div className="lateVidContent">
        <h2>Late Video Upload</h2>
        {videos.map(renderVideo)}
      </div>
    </div>
  );
};

export default LateVid;
