import React, { useState, useEffect } from 'react';
import firebase from '../../firebaseConfige';
import 'firebase/compat/firestore';
import './under.scss';
import Navbar from '../../componet/topbar/Topbar';

const UserA = () => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const snapshot = await firebase.firestore().collection('admin-user').get();
        const fetchedAccounts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();

    // Clean-up function
    return () => {};
  }, []);

  const disableAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).update({ status: 'Disabled' });
      setAccounts(prevAccounts =>
        prevAccounts.map(account =>
          account.id === accountId ? { ...account, status: 'Disabled' } : account
        )
      );
    } catch (error) {
      console.error('Error disabling account:', error);
    }
  };

  const approveAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).update({ status: 'Active' });
      setAccounts(prevAccounts =>
        prevAccounts.map(account =>
          account.id === accountId ? { ...account, status: 'Active' } : account
        )
      );
    } catch (error) {
      console.error('Error approving account:', error);
    }
  };

  const terminateAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).delete();
      setAccounts(prevAccounts =>
        prevAccounts.filter(account => account.id !== accountId)
      );
    } catch (error) {
      console.error('Error terminating account:', error);
    }
  };

  return (
    <div className="user">
      <div className="userA">
        <h2>Accounts List</h2>
        <div className="accounts">
          {accounts.map(account => (
            <div key={account.id} className="account">
              <div className="profilePicContainer">
                <img src={account.profileImage} alt={'Profile of ${account.name}'} className="profilePic" />
              </div>
              <div className="accountInfo">
                <p>Name: {account.name}</p>
                <p>Status: {account.category}</p>
                <button className='approve' onClick={() => approveAccount(account.id)}>Approve</button>
                <button className='disable' onClick={() => disableAccount(account.id)}>Disable</button>
                <button className='terminte' onClick={() => terminateAccount(account.id)}>Terminate</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserA;