import React from 'react';
import './search.scss';
import Navbar from '../../componet/topbar/Topbar'; // Import SCSS file for styles

const SearchPage = () => {
  const handleSearch = (query) => {
    // Implement your search logic here
    console.log('Searching for:', query);
    // For example, you can make an API call to fetch search results based on the query
  };

  return (
    <div className="navigation">
      <Navbar />
 <div className="search-page-container">
     
     <h1>Search Page</h1>
     <div className="search-form">
       <input type="text" placeholder="Enter your search query" />
       <button onClick={() => handleSearch('search query')}>Search</button>
     </div>
     <div className="search-results">
       {/* Display search results here */}
       <p>Search results will appear here...</p>
     </div>
   </div>
    </div>
   
  );
};

export default SearchPage;