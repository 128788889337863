import React, { useState, useEffect } from 'react';
import 'firebase/firestore'; // Import firestore module only
import './letblog.scss';
import firebase from '../../firebaseConfige'; // Import firebase app module
import Navbar from '../../componet/topbar/Topbar';

const Letblog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Firestore reference
    const db = firebase.firestore();

    // Fetch blogs from Firestore
    const fetchBlogs = async () => {
      try {
        const blogsRef = db.collection('blogs');
        const snapshot = await blogsRef.get();
        const fetchedBlogs = [];
        snapshot.forEach(doc => {
          fetchedBlogs.push({ id: doc.id, ...doc.data() });
        });
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error('Error fetching blogs: ', error);
      }
    };

    fetchBlogs();

    // No need for cleanup function because Firebase is not initialized here

  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
   <div className="blo">
    <Navbar/>
     <div className="container">
      <h2>User Blogs</h2>
      {blogs.map(blog => (
        <div className="blog" key={blog.id}>
          <h3>{blog.title}</h3>
          <p>{blog.content}</p>
        </div>
      ))}
    </div>
   </div>
  );
};

export default Letblog;
