import React from 'react';
import './NotificationsPage.scss';
import Navbar from '../../componet/topbar/Topbar';


const NotificationsPage = () => {
  return (
    <div className="notification">
        <Navbar />
        <div className="notifications-page-container">
      <h1>Notifications</h1>
      <div className="notifications-list">
        {/* Display notifications here */}
        <div className="notification-item">
          <p>This is a sample notification.</p>
          <span className="notification-time">2 hours ago</span>
        </div>
        {/* Add more notification items as needed */}
      </div>
    </div>
    </div>
    
  );
};

export default NotificationsPage;