import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './dashboard.scss'
import Navbar from '../../componet/topbar/Topbar';
import Under from '../../pages/under/Under';
const AnalyticsCharts = () => {
  const chartRef = useRef(null);
  let myChart = null;

  useEffect(() => {
    // Ensure cleanup when the component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext('2d');
    
    // Dummy data for analytics chart
    const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', ],
      datasets: [
        {
          label: 'Users',
          data: [100, 200, 300, 250, 400, 350],
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          fill: false,
        },
      ],
    };

    // Creating chart using Chart.js
    myChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    // Cleanup when the chart component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, []);

  return (
   <div className="an">
    <Navbar/>
     <div className="analytics-chart">
      <canvas ref={chartRef} />
    </div>
    <Under/>
   </div>
  );
}

export default AnalyticsCharts;