import React from 'react';
import './account.scss'
import Navbar from '../../componet/topbar/Topbar'

const AccountPage = () => {
  return (
    <div className="Account">
      <Navbar />
      <div className="account-page-container">
      <h1>Account Settings</h1>
      <div className="account-details">
        <div className="account-item">
          <p><strong>Name:</strong> Admin</p>
        </div>
        <div className="account-item">
          <p><strong>Email:</strong> Admin@example.com</p>
        </div>
        <div className="account-item">
          <p><strong>Role:</strong> Admin</p>
        </div>
        <div className="account-item">
          <p><strong>Language:</strong> English</p>
        </div>
        {/* Add more account details or settings as needed */}
        <div className="account-item">
          <button className="update-btn">Update Profile</button>
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default AccountPage;